@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=3ef94124-46c7-457b-84b1-b5d33392cdfa");
@font-face{
    font-family:"Futura PT W01 Light";
    src:url("../fonts/9869762c-2f06-40c3-929e-2d412d64cd87.eot?#iefix");
    src:url("../fonts/9869762c-2f06-40c3-929e-2d412d64cd87.eot?#iefix") format("eot"),url("../fonts/9ad55860-bbe2-4e51-be58-08b45bdda354.woff2") format("woff2"),url("../fonts/eceb6e13-403b-4d2b-af74-c05bc9c2535e.woff") format("woff"),url("../fonts/956fd236-834e-4736-91af-a04cadf17544.ttf") format("truetype"),url("../fonts/88e3bfcb-64f4-4e6f-9785-472510bd5208.svg#88e3bfcb-64f4-4e6f-9785-472510bd5208") format("svg");
}
@font-face{
    font-family:"FuturaPTW01-LightObliqu";
    src:url("../fonts/40f2d0be-81d9-4f03-83e2-8823175e604f.eot?#iefix");
    src:url("../fonts/40f2d0be-81d9-4f03-83e2-8823175e604f.eot?#iefix") format("eot"),url("../fonts/adde7586-049c-4682-b4ea-763ca90ccf9f.woff2") format("woff2"),url("../fonts/8cb58a58-1da7-4cda-8284-52cf5bf67bf5.woff") format("woff"),url("../fonts/6c33fee6-2cc1-4ab5-9f6e-785893bc3245.ttf") format("truetype"),url("../fonts/4dfa4739-61b4-454d-a6ba-e1160c446b4d.svg#4dfa4739-61b4-454d-a6ba-e1160c446b4d") format("svg");
}
@font-face{
    font-family:"Futura PT W01 Book";
    src:url("../fonts/17abcb18-cb81-4d9c-b55c-5ded2109e506.eot?#iefix");
    src:url("../fonts/17abcb18-cb81-4d9c-b55c-5ded2109e506.eot?#iefix") format("eot"),url("../fonts/aafdd021-88d4-41d8-8e15-fe91f83e4b17.woff2") format("woff2"),url("../fonts/81d44d4b-ac07-4ad4-8cdd-4df771a3c1c3.woff") format("woff"),url("../fonts/f47c9a36-7884-483a-80d7-57f9ee691407.ttf") format("truetype"),url("../fonts/b6a5374f-5656-4627-b423-1ba121767464.svg#b6a5374f-5656-4627-b423-1ba121767464") format("svg");
}
@font-face{
    font-family:"FuturaPTW01-BookOblique";
    src:url("../fonts/d7a84526-5c51-462c-bdef-e84462e2cb44.eot?#iefix");
    src:url("../fonts/d7a84526-5c51-462c-bdef-e84462e2cb44.eot?#iefix") format("eot"),url("../fonts/28351402-8fc5-4d21-a3df-e99d2d814bd4.woff2") format("woff2"),url("../fonts/10cee8c3-6e4c-4f57-8121-cf5e361423a7.woff") format("woff"),url("../fonts/938e03f7-a623-4f23-aa2b-f6efdc03d731.ttf") format("truetype"),url("../fonts/39954dc0-891f-4172-951b-e7eebeeee411.svg#39954dc0-891f-4172-951b-e7eebeeee411") format("svg");
}
@font-face{
    font-family:"Futura PT W01 Medium";
    src:url("../fonts/c0b084f4-7139-4e63-ba96-7f3a421195c3.eot?#iefix");
    src:url("../fonts/c0b084f4-7139-4e63-ba96-7f3a421195c3.eot?#iefix") format("eot"),url("../fonts/f7552419-a773-41ab-ae4a-b12b1d853f02.woff2") format("woff2"),url("../fonts/83e72918-97c3-41cd-8a7d-4056788a00f0.woff") format("woff"),url("../fonts/5b88228b-bd3b-49f4-b7c2-db89968ce116.ttf") format("truetype"),url("../fonts/20048613-c880-452b-a12c-fc1ea23e8d5f.svg#20048613-c880-452b-a12c-fc1ea23e8d5f") format("svg");
}
@font-face{
    font-family:"FuturaPTW01-MediumObliq";
    src:url("../fonts/43b27e2f-6376-43ee-8a63-020009fa45a1.eot?#iefix");
    src:url("../fonts/43b27e2f-6376-43ee-8a63-020009fa45a1.eot?#iefix") format("eot"),url("../fonts/e28dd043-42c3-47cb-815e-32648d6a8a2a.woff2") format("woff2"),url("../fonts/97eeb983-cbcb-40c4-b724-919d44b8d4af.woff") format("woff"),url("../fonts/2889ab2c-64cf-45c0-be86-e14cc28b324a.ttf") format("truetype"),url("../fonts/870852b2-1fbd-44b8-bf52-1f6c79f4679b.svg#870852b2-1fbd-44b8-bf52-1f6c79f4679b") format("svg");
}
@font-face{
    font-family:"Futura PT W01 Bold";
    src:url("../fonts/88a061c2-3303-401d-85e9-619b5ea5f5af.eot?#iefix");
    src:url("../fonts/88a061c2-3303-401d-85e9-619b5ea5f5af.eot?#iefix") format("eot"),url("../fonts/1a34942e-33ed-43bb-b229-7460d55b49f7.woff2") format("woff2"),url("../fonts/baecea54-cfd3-4578-8717-abf89eba62b6.woff") format("woff"),url("../fonts/f275eebe-30f5-4068-9294-51dc44c8409e.ttf") format("truetype"),url("../fonts/d69595d3-2967-4e10-a909-435e883354e7.svg#d69595d3-2967-4e10-a909-435e883354e7") format("svg");
}
@font-face{
    font-family:"FuturaPTW01-BoldOblique";
    src:url("../fonts/ac927c46-6a39-45fb-80d4-890aabc37576.eot?#iefix");
    src:url("../fonts/ac927c46-6a39-45fb-80d4-890aabc37576.eot?#iefix") format("eot"),url("../fonts/98311280-30b7-45f8-9d75-0885d44b1f29.woff2") format("woff2"),url("../fonts/77c9f834-2d88-4754-a7fc-6e14ec3412e8.woff") format("woff"),url("../fonts/d833e5ba-d967-4a8c-af01-680d93581041.ttf") format("truetype"),url("../fonts/9834b2aa-22f3-4081-8b7a-373a10fc789a.svg#9834b2aa-22f3-4081-8b7a-373a10fc789a") format("svg");
}
@font-face{
    font-family:"Futura PT W01 Heavy";
    src:url("../fonts/40d43dca-5382-4730-9fe0-12297d982530.eot?#iefix");
    src:url("../fonts/40d43dca-5382-4730-9fe0-12297d982530.eot?#iefix") format("eot"),url("../fonts/6650716f-e079-48f1-861d-00f498c16e8e.woff2") format("woff2"),url("../fonts/21fe5b07-01c8-4b82-8e0b-2bf22f6481fa.woff") format("woff"),url("../fonts/ecfba8b5-c926-413d-8ef7-36873ee7e39e.ttf") format("truetype"),url("../fonts/07af89fc-cc7d-41bc-9a2b-ff72607df969.svg#07af89fc-cc7d-41bc-9a2b-ff72607df969") format("svg");
}
@font-face{
    font-family:"FuturaPTW01-HeavyObliqu";
    src:url("../fonts/d6830824-38de-49bb-8f60-1a12a8c4dabb.eot?#iefix");
    src:url("../fonts/d6830824-38de-49bb-8f60-1a12a8c4dabb.eot?#iefix") format("eot"),url("../fonts/cf12a4d6-3bc0-4ae9-85b5-26fc0fa6b321.woff2") format("woff2"),url("../fonts/38909ddd-1d42-49a0-987a-dcf1aa3ab39e.woff") format("woff"),url("../fonts/ae134e79-df2c-4ff1-b1ee-2be7df55d561.ttf") format("truetype"),url("../fonts/97cfb96c-a02f-4b54-8839-4cbeded67b83.svg#97cfb96c-a02f-4b54-8839-4cbeded67b83") format("svg");
}
@font-face{
    font-family:"Trajan W01 Regular";
    src:url("../fonts/5cbd8dde-fb39-4dd1-aa25-5bb5842eb03d.eot?#iefix");
    src:url("../fonts/5cbd8dde-fb39-4dd1-aa25-5bb5842eb03d.eot?#iefix") format("eot"),url("../fonts/610145ac-dd31-4024-b833-3ab0ce6b7847.woff2") format("woff2"),url("../fonts/b4312891-e355-4bdc-80c9-ef3dbf7bd916.woff") format("woff"),url("../fonts/124dc47b-32a0-4543-a60e-d8aad32782b4.ttf") format("truetype"),url("../fonts/001e534b-3742-45b3-b20b-d4c53c41a519.svg#001e534b-3742-45b3-b20b-d4c53c41a519") format("svg");
}
@font-face{
    font-family:"Trajan W01 Bd";
    src:url("../fonts/a602588d-0fb5-40a8-a1b9-112c323b8a73.eot?#iefix");
    src:url("../fonts/a602588d-0fb5-40a8-a1b9-112c323b8a73.eot?#iefix") format("eot"),url("../fonts/7255bae8-e86a-4f7e-bb5d-8e99aebbea20.woff2") format("woff2"),url("../fonts/42eddbd0-3b0e-4c24-ba3b-57b9859b2602.woff") format("woff"),url("../fonts/a901f152-b0d8-4686-bbaa-8a5aff10781b.ttf") format("truetype"),url("../fonts/5fac8357-5dba-4c53-80c1-607abd0eae0e.svg#5fac8357-5dba-4c53-80c1-607abd0eae0e") format("svg");
}

$futura-fallback: Arial, sans-serif;
$trajan-fallback: Arial, sans-serif;

$futura-light: "Futura PT W01 Light", $futura-fallback;
$futura-light-oblique: "FuturaPTW01-LightObliqu", $futura-fallback;
$futura-book: "Futura PT W01 Book", $futura-fallback;
$futura-book-oblique: "FuturaPTW01-BookOblique", $futura-fallback;
$futura-medium: "Futura PT W01 Medium", $futura-fallback;
$futura-medium-oblique: "FuturaPTW01-BookOblique", $futura-fallback;
$futura-bold: "Futura PT W01 Bold", $futura-fallback;
$futura-bold-oblique: "FuturaPTW01-BoldOblique", $futura-fallback;
$futura-heavy: "Futura PT W01 Heavy", $futura-fallback;
$futura-heavy-oblique: "FuturaPTW01-HeavyObliqu", $futura-fallback;
$trajan-regular: "Trajan W01 Regular", $trajan-fallback;
$trajan-bold: "Trajan W01 Bd", $trajan-fallback;

// Colors
$blue-primary: #2a265f;
$blue-tint-dark: #5f5c8d;
$blue-tint-light: #817ccb;
$grey-medium: #888888;
$grey-line: #cccccc;
$grey-background: #eff1f3;
$white: #ffffff;

// Breakpoints
$xl-screen: 1199px;
$l-screen: 1023px;
$m-screen: 767px;
$s-screen: 600px;
$xs-screen: 480px;
$xxs-screen: 320px;

// HEADER TRANSITION
$logo-small-transition: -50%;
$logo-small-size: 45px;
