/* TEXT */

.link {
	color: #2a265f!important;
}

.text_block { text-align:center; font-size: 20px; padding-bottom:15px; }

.disclaimer-text { font-size:14px; }

.hero_header_text {
	color: #ffffff;
	font-size: 50px;
	text-align: center;
	text-transform: uppercase;
}

.subheader_text {
	color: #2a265f;
	font-size: 31px;
	text-align: center;
	line-height: 1.2;

}

.intro_text,
.page_text_container p {
	color: #888888;
	font-size: 21px;
	text-align: center;
	line-height: 1.2;
}

.intro_text_left {
	text-align: left;
}

.page_text_container p,
.section_text p {
	font-size: 20px;
	text-align: left;
}
.grid_title_text,
.document_repository .title,


.grid_title_text_2 {
	line-height: 25px;
}

.section_title {
	font-size: 30px!important;
	margin-bottom: 25px;
}

.icon {
	font-size: 40px;
	text-align: center;
	color: #2a265f;
	p {
		font-size: 20px;
		text-transform: uppercase;
	}
}

.grid_section_title {
	text-align: center;
	padding: 30px 0;
	font-size: 25px;
}

.grid_section_title_2 {
	text-align: center;
	padding-top: 50px;
	font-size: 25px;
}

.directions_container {
	h1 {
		font-size: 40px;
		i {
			padding-right: 15px;
		}
	}
	
	h2 {
		font-size: 25px;
		padding: 30px 0;
		font-family: $futura-book;
		text-transform: none;
	}
	
	p {
		font-size: 18px;
	}
	
	.col-md-4 {
		text-align: center;
		color: #2a265f;
	}
}

.accordion {


	line-height: 15px;
	.title {
		font-size: 20px;
		color: #2a265f;
		text-transform: uppercase;
		float:left;	
		width:60%;
	}
	
	.subtitle {
		color: #817ccb;
		width:30%;
		
	}
	

	.icon {
		i {
			float: right;
			font-size: 25px;
		}
		width:10%;	
	}
}

.document_repository {
	.title{ 
		text-align: center;
		font-size: 23px;
	}
	.col-md-3,
	.col-md-4{
		text-align: center;
		color: #2a265f;
		text-transform: uppercase;
	}
	i {
		font-size: 30px;
	}

}

.grid_text {
	color: #888888;
	font-size: 18px;
}

.under_title_text {
	font-size: 18px;
	text-transform: none;
}

.quotes_container p {
	color: #2a265f;
	font-size: 20px;
	font-style: oblique;
	text-align: center;
}

.info_row {
	text-align: center;
	i {
		color: #817ccb;
		font-size: 30px;
	}
	.iconLabel {
		color: #817ccb;
		text-transform: uppercase;
	}
	p {
		padding-top: 10px;
		color: white;
		a {
			color: #817ccb;
		}
	}
	
	.text {
		padding-top: 0;
	}
	
	.info_row_text {
		padding: 10px 0 30px 0;
		font-size: 20px;
	}
}

.contact_village,
.contact_team,
.leave_message {

	h3 {
		text-align: center;
		font-size: 23px;
	}
	.contact_grid {
		.grid_header_box {
			color: white;
			text-transform: uppercase;
		}
		.contact_title {
			color: #2a265f;
			padding-right: 50px;
		}
		p {
			padding-top: 25px;
			font-size: 15px;
		}
	}
}

button,
.side_menu_container .active,
.submit_contact_form{
	width:100%;
	color: white;
	background-color: #2a265f;
	text-transform: uppercase;
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 18px;
	border: 0;
}


.subtitle_button_container,
.submit_contact_form{
	margin-top: 30px;
	text-align: center;
	button {
		width: auto;
		padding-left: 50px;
		padding-right: 50px;
	}
	button:nth-child(2) {
		margin-left: 20px;
	}
}

.submit_contact_form {
	width: auto;
	padding-left: 50px;
	padding-right: 50px;
}
.submit_contact_form_p {
	text-align: center;
}
.pagination {
	text-align: center;
	button {
		width: 0px;
		padding: 5px 20px 5px 10px;
		text-align: center;
	}
	.active {
		color: white!important;
		background-color: #2a265f!important;
	}
}

.side_menu_container,
.pagination:not(.active){
	button {
		background-color: #eff1f3;
		color: #888888;
	}
	
	button:not(.active):hover {
		color: #ffffff;
		background-color: #817ccb;
	}
}

.hero_container {
	padding: 190px 0 0 0;
	.hero_image_container {
		padding: 140px 0;
		width: 100%;
		background-position: center center;
		background-repeat: no-repeat;
	}
}

.subtitle_container {
	background-color: #eff1f3;
	.subtitle_text_container {
		max-width: 1000px;
		width: 100%;
		padding: 50px 0;
		margin: auto;
	}
}

.page-container {
	padding-top: 50px;
}

.multi_row_grid_container {
	
	.grid_box_blog:nth-child(1),
	.grid_box_blog:nth-child(2) {
		margin-top: 1px!important;
	}
	
	.grid_box {
		margin-top: 50px;
		img {
			width: 100%;
		}
		.text_container {
			.grid_title_text {
				margin-bottom: 20px;
			}
			padding: 20px 20px 30px 20px;
			border-right: 5px solid #eff1f3;
			border-left: 5px solid #eff1f3;
		}
		.no_button {
			border-bottom: 5px solid #eff1f3;
		}
		.contact_text_container {
			border: 5px solid #888888;
			border-top: 0px;
		}
	}
	.grid_box_badges {
		img {
			width: 60%;
		}
		line-height: 20px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		.under_title_text {
			color: #817ccb;
			font-size: 20px;
			text-transform: uppercase!important;
		}
		.text_container {
			border: 0;
		}
		.link {
			color: #2a265f;
			text-decoration: underline;
		}
	}
}

.quotes_container {
	hr {
		border-color: #cccccc;
	}
	p {
		padding: 15px 0;
		margin: auto;
	}
}

.quotes_container_2 {
	p {
		max-width: 800px;
	}
}

.document_repository {
	.row {
		max-width: 800px;
		margin: auto;
	}
	width: 100%;
	border: 4px solid #eff1f3;
	margin-top: 50px;
	padding: 30px;
	i {
		padding: 30px;
		margin: 30px 0;
		background-color: #eff1f3;
	}
}

.gallery_container {
	background-color: #eff1f3;
	.row {
		max-width: 1000px;
		margin: auto;
	}
	margin-top: 60px;
		padding: 50px;
		padding-top: 20px;
		.grid_box {
			margin-top: 30px;
		}
	img {
		cursor: pointer;
	}
}

.info_row {
	background-color: #2a265f;
	padding-bottom: 30px;
	margin-top: 50px;
}

.late_availability {
	margin-top: 0!important;
	padding-top: 20px;
}

.accordion {
	width: 100%;
	margin-top: 10px;
	padding: 15px;
	background-color: #eff1f3;
}

.dynamic_nav_container {
	border: 5px solid #eff1f3;
	margin-top: 50px;
	padding: 20px;
	.search_box {
		width: calc(100% - 40px);
		padding: 15px;
		border: 1px solid #eff1f3;
		margin: 10px 0 20px 20px;
	}
	
	.checkbox_container {
		padding: 20px;
		padding-top: 5px;
		span {
			display: block;
			padding: 2px;
		}
		.active {
			color: #2a265f;
		}
	}
}

.pagination {
	float: right;
	padding-right: 50px;
}

.pagination.bottom {
		padding-top: 15px!important;
}

.content_container {
	padding-top: 30px;
}

.small_grid {
	text-align: center;
	.icon_container {
		padding: 20px;
	}
}

.directions_container {
	.sub_container {
		padding: 50px 0;
	}
	.sub_container:nth-child(even) {
		background-color: #eff1f3;
	}
	
	.col-md-4 {
		padding: 20px;
		.text_container {
			padding: 20px 0;
			border: 5px solid #eff1f3;
			i {
				font-size: 25px;
				padding-bottom: 10px;
			}
			p {
				margin-bottom: 10px;
			}
		}
	}
}

.center-vertical {
	position: relative!important;
	top: 50%!important;
	transform: translateY(50%)!important;
	h3 {
		font-size: 25px;
	}
	p {
		padding-top: 15px;
	}
}

.contact_team {
	background-color: #eff1f3;
}

.contact_village,
.contact_team,
.leave_message {
	padding: 50px 0;
	.contact_grid {
		.grid_header_box {
			background-color: #2a265f;
			padding: 10px 20px;
		}
	}
}

.contact_form_table {
	p {
		color: #2a265f;
		text-transform: uppercase;
	}
	textarea {
		width: calc(100% - 20px);
		border: 5px solid #eff1f3;
		padding: 20px;
		border-radius: 5px;
	}
	margin: 50px;
	width: 100%;
	table-layout: fixed;
	input[type="text"] {
		width: calc(100% - 30px);
		border: 5px solid #eff1f3;
		border-radius: 5px;
		padding: 10px 10px;
		
	}
}


.gallery_page_container {

	.overlay {
		background-color: #000;
		position: relative;
		height: 100%;
		width: 100%;
		.text {
			display: none;
			position: absolute;
			text-align: center;
			color: white;
			width: 100%;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			i {
				font-size: 40px;
				display: block;
				padding-bottom: 25px;
			}
			.fa-play-circle {
				padding-bottom: 0!important;
				font-size: 90px!important;
			}
		}
		.not_hidden {
			display: inline-block;
		}
	}
	
	.overlay:hover {
		.text {
			display: inline;
		}
	}

	.row {
		padding: 10px;
		padding-bottom: 0;
	}
	.col-md-3 {
		padding: 0!important;
	}
	.img_container{
		padding: 10px;
		width: 100%;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
		}
		img:hover {
			opacity: 0.2;
		}
	}
	
	.image_viewer{
        display: none;  
		position: fixed;
		z-index: 1001;
      	top: 0px;
      	left: 0px;
       	width: 100%;
     	height: 100%;
      	background-color:rgba(0,0,0,0.8);
	
		.image_container {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			max-width: 50%;
			max-height: 70%;
			button {
				position: absolute;
				width: auto;
				top: 50%;
				padding: 15px 20px;
				font-size: 20px;
			}
			button.left {
				left: 0;
				transform: translate(-50%,-100%);
			}
			button.right {
				right: 0;
				transform: translate(50%,-100%);
			}
			
			p {
				color: white;
				text-align: center;
				font-size: 18px;
				padding-top: 15px;
			}
		}
		
		.exit {	
			position: absolute;
			top: 10px;
			right: 25px;
			font-size: 50px;
		}
		
		.exit:hover {
			color: white;
			cursor: pointer;
		}
	
	}
}


.even2 {
	background-color: white!important;
}
.odd2 {
	background-color: #eff1f3!important;
}

.end {
	padding: 30px 0;
}