
.header{
	position: fixed;
	z-index: 1000; /*100000*/
	width: 100%;
	background-color: $white;

	@media(max-width: $m-screen){
		&.navbar-open{
			overflow-y: scroll;
			height: 100%;
		}
	}

	.header-bar{
		height: 140px;
		padding: 30px 0;
		-webkit-transition: all 0.2s linear;
	   	-moz-transition: all 0.2s linear;
	   	-ms-transition: all 0.2s linear;
	   	-o-transition: all 0.2s linear;
	   	transition: all 0.2s linear;

		&.small-bar{
			height: 55px;
			padding: 15px 0;
		}

		.header-bar-inner{
			position: relative;
			height: 100%;
		}

		.header-logo{
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: 	translate($logo-small-transition, $logo-small-transition);
			-moz-transform: 	translate($logo-small-transition, $logo-small-transition);
			-ms-transform: 		translate($logo-small-transition, $logo-small-transition);
			-o-transform: 		translate($logo-small-transition, $logo-small-transition);
			transform: 			translate($logo-small-transition, $logo-small-transition);
			height: 110px;
			width: 110px;

			-webkit-transition: height 0.2s, width 0.2s linear;
		   	-moz-transition: height 0.2s, width 0.2s linear;
		   	-ms-transition: height 0.2s, width 0.2s linear;
		   	-o-transition: height 0.2s, width 1s linear;
		   	transition: height 0.2s, width 0.2s linear;

			&.small-logo{
				height: $logo-small-size;
				width: $logo-small-size;
			}
		}
		.header-links{
			position: absolute;
			top: 0;
			left: 0;

			> ul > li{
				margin-right: 20px;
			}

			ul li a:not(.inactive){
				color: $blue-primary;

			}
		}
		.header-weather{
			position: absolute;
			bottom: 0;
			left: 0;

			> div{
				float: left;
				margin-right: 25px;
				text-align: center;

				.degrees{
					color: $grey-medium;
				}
			}
		
			
		}
		.header-details{
			position: absolute;
			top: 0;
			right: 0;



			> ul{
				margin-left: 0;
				margin-right: -5px;

				> li{
					margin-left: 20px;

					@media(max-width: $l-screen){
						width: 100%;
						text-align: right;
						margin-left: 0;
						padding: 0;

						> a{
							line-height: 5px;
						}
					}
				}
			}
		}
		.header-book{
			position: absolute;
			bottom: 0;
			right: 0;

			a{
				font-family: $futura-medium;
				font-size: 22px;
				color: $blue-primary;
				text-transform: uppercase;
			}
		}

		.header-book-mobile{
			position: absolute;
			top: 50%;
			-webkit-transform: 	translateY(-50%);
			-moz-transform: 	translateY(-50%);
			-ms-transform: 		translateY(-50%);
			-o-transform: 		translateY(-50%);
			transform: 			translateY(-50%);
			right: 0;
			text-align: center;

			a{
				font-family: $futura-medium;
				font-size: 22px;
				color: $blue-primary;
				text-transform: uppercase;
				line-height: 23px;
			}
		}

		.header-menu{
			position: absolute;
			top: 50%;
			-webkit-transform: 	translateY(-50%);
			-moz-transform: 	translateY(-50%);
			-ms-transform: 		translateY(-50%);
			-o-transform: 		translateY(-50%);
			transform: 			translateY(-50%);
			left: 0;

			height: 48px;
			width: 60px;
		}
	}

	nav{
		&.navbar{
			margin-bottom: 0;
		}

		&.navbar-default{
			background-color: #fff;
		}

		@media(min-width: $m-screen + 1){
			&.navbar-default{
				border-top: 1px solid #cccccc;
			}

			.navbar-collapse{
				padding-right: 0;
				padding-left: 0;
			}

			.navbar-nav{
				width: 100%;
				text-align: center;
				> li{
					float: none;
					display: inline-block;

					> a{
						color: $blue-primary;
						font-family: $futura-medium;
						font-size: 16px;
						text-transform: uppercase;

						&:hover, &:focus{
							color: $blue-primary;
						}
					}

					&.open, &:hover, &:focus{
						background-color: $blue-primary;

						> a, a:hover, > a:focus{
							color: $white;
							background-color: $blue-primary;
						}
					}
				}

				.open ul{
					display: none;
				}

				&.hovernav > li:hover > .dropdown-menu{
					display: block;
				}
			}

			.nav > li, .nav > li.dropdown{
				position: static;
			}

			.dropdown-menu{
				width: 100%;
				background-color: $blue-primary;
				padding-top: 50px;
				padding-bottom: 50px;
				border-bottom: 10px solid #8681c5;

				.pre-heading{
					color: #8681c5;
					margin-bottom: 10px;
				}
				.heading{
					color: $white;
				}
				li > a{
					color: $white;
					font-family: $futura-book;
					font-size: 17px;
				}
				hr{
					border-color: #8681c5;
				}

				.featured-link-box{
					.image{
						margin-bottom: 20px;
						border: 2px solid #8681c5;
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center center;
					}
					.title{
						color: $white;
						margin-bottom: 15px;
					}
					.description{
						color: $white;
						font-size: 17px;
						font-family: $futura-medium;
					}
				}
			}
			
			.dropdown-menu::after {
			  content: "";
			  background-image:url("/images/menu-images/Late Sun Over The Estuary.jpg");
			  background-position: center; 
			  opacity: 0.08;
			  top: 0;
			  left: 0;
			  bottom: 0;
			  right: 0;
			  position: absolute;
			  z-index: -1;   
			}				
		}

		@media(max-width: $m-screen){
			&.navbar{
				min-height: 0;
			}

			.navbar-collapse{
				border-top: 2px solid $blue-primary;
			}

			.navbar-nav{
				margin-top: 0;
				margin-bottom: 0;

				> li{
					border-bottom: 1px solid $grey-line;
					position: relative;

					&::before{
						content: "\f054";
						font-family: "Font Awesome 5 Pro";
						font-size: 14px;
						color: $blue-primary;
						position: absolute;
						top: 20px;
						right: 15px;
					}

					> a{
						line-height: 16px;
						padding-top: 20px;
						padding-bottom: 20px;
						text-transform: uppercase;
						color: $blue-primary;

						&:hover, &:focus{
							color: $blue-primary;
						}
					}

					&.open{
						&::before{
							content: "\f054";
							font-family: "Font Awesome 5 Pro";
							font-size: 14px;
							color: $white;
							position: absolute;
							top: 20px;
							right: 15px;
							z-index: 2;
						}

						> a{
							background-color: #8681c5 !important;
							color: $white !important;
						}
					}
				}
			}

			.dropdown-menu{
				padding: 0;

				.container{
					padding: 0;
				}

				li{
					border-bottom: 1px solid #8681c5;
					position: relative;

					&::before{
						content: "\f054";
						font-family: "Font Awesome 5 Pro";
						font-size: 14px;
						color: $white;
						position: absolute;
						top: 20px;
						right: 15px;
					}

					> a{
						display: block;
						padding: 20px 15px;
						color: $white;
						text-transform: uppercase;
						background-color: $blue-primary;

						&:hover{
							text-decoration: none;
						}
					}
				}
			}
		}
	}

	.navbar-toggle{
		float: none;
		margin: 0;
		padding: 0;
		cursor: pointer;
	}
}

/* Body */
body.book-on{
	overflow: hidden;
}
.book{
	display: none;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0,0,0,0.7);
	z-index: 100049;
}
.book-now{
	display: none;
	position: absolute;
	right: 0;
	top: 0;
	width: 475px;
	height: 100vh;
	overflow-y: scroll;
	z-index: 100050;
	background-color: $white;

	@media(max-width: $xs-screen){
		width: 100%;
	}

	.book-now-inner{
		position: relative;
		padding: 50px;

		.close-button{
			position: absolute;
			top: 15px;
			left: 15px;
			cursor: pointer;
		}

		.title{
			margin-bottom: 50px;
		}

		.rooms-details{
			border-top: 1px solid $grey-line;
			padding-top: 20px;
			display: none;

			.room-guests{
				border-bottom: 1px solid $grey-line;
				margin-bottom: 20px;
				display: none;

				.title{
					font-family: $futura-medium;
					color: $blue-primary;
					font-size: 20px;
					text-transform: uppercase;
					margin-bottom: 20px;
				}
			}
		}

		.book-error{
			padding-top: 10px;
			padding-bottom: 10px;
			display: none;
			color: #a94442;
		}

		.select2{
			width: 100% !important;

			&.select2-container{
				vertical-align: baseline;
			}
			.select2-selection{
				border: none;
				border-radius: 0;
				height: 48px;
			}
			.select2-selection__rendered{
				color: $grey-medium;
				text-align: left;
				font-family: $futura-medium;
				font-size: 16px;
				padding: 6px 12px;
				height: 48px;
				background-color: $grey-background;
				line-height: 32px;
			}
			.select2-selection__placeholder{
				color: $grey-medium;
			}
			.select2-selection__arrow{
				width: 13px;
				top: 11px;
				right: 21px;
			}
			b{
				display: none;
			}
			.select2-selection__arrow::before{
				content: "\f078";
				font-family: "Font Awesome 5 Pro";
				color: $grey-medium;
				font-size: 12px;
			}
		}

		.blue{
			width: 100%;
		}
	}
}
.select2-dropdown{
	z-index: 100051;
}