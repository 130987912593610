.main-wpr{
	padding-top: 190px;
}

/* Home page */
.hero-video{
	/* height: 100vh; */
	padding-top: 190px;
	background-color: $grey-background;
	position:relative;
}

.hero-video-container {
	z-index:5;
}

.hero-video-controls-container {
	height:100%;
	position:absolute;
	top:220px;
	left:0px;
	text-align:center;
	width:100%;
	z-index:10;
	/* background-color:#000000; */
}

.hero-video-controls {
	margin-top:100px;
}

.hero-video-controls h1 {
	color:#FFFFFF;
}


.page-intro{
	padding: 60px 20px;
	text-align: center;

	@media(min-width: $m-screen + 1){
		padding-left: 25%;
		padding-right: 25%;
	}
}

.tour-search{
	background-color: $blue-primary;
	text-align: center;
	color: $white;
	padding: 30px 20px;

	.list-inline{
		margin-bottom: 0;

		li{
			padding-left: 10px;
			padding-right: 10px;

			@media(max-width: $m-screen){
				width: 100%;
			}
		}
	}

	select{
		display: none !important;
	}

	.title{
		font-family: $futura-book;
		text-transform: uppercase;
		font-size: 15px;
	}
	.select-label{
		font-family: $futura-book;
		font-size: 24px;
		margin-bottom: 0;
	}

	@media(max-width: $m-screen){
		.title{
			margin-bottom: 20px;
		}

		.select-label{
			margin-bottom: 10px;
		}
	}

	.select2{
		&.select2-container{
			vertical-align: baseline;
		}
		.select2-selection{
			background-color: transparent;
			border: none;
			border-radius: 0;
			border-bottom: 1px solid $white;
		}
		.select2-selection__rendered{
			color: #5f5c8d;
			padding-left: 0;
			text-align: left;
			font-family: $futura-book-oblique;
			font-size: 20px;
			width: 260px;
		}
		.select2-selection__placeholder{
			color: #5f5c8d;
		}
		.select2-selection__arrow{
			width: 13px;
		}
		b{
			display: none;
		}
		.select2-selection__arrow::before{
			content: "\f078";
			font-family: "Font Awesome 5 Pro";
			color: $white;
			font-size: 12px;
		}

		@media(max-width: $m-screen){
			&.select2-container{
				width: 100% !important;
			}
			/*.select2-selection--single{
				height: 50px;
			}
			.select2-selection__rendered{
				font-size: 40px;
				line-height: 40px;
			}
			.select2-selection__arrow{
				width: 22px;
				top: 10px;
			}
			.select2-selection__arrow::before{
				font-size: 20px;
			}*/
		}
	}
}
.select2-dropdown{
	border-radius: 0;
}

.page-section-boxes{
	.section-box{
		padding: 60px 0;

		@media(max-width: $l-screen){
			padding: 0;
			display: block;
			width: 100%;

			.container{
				padding-left: 0;
				padding-right: 0;
				width: 100%;
			}
		}

		.slider{
			position: relative;

			.slider-inner{

				.slick-slider{
					margin-bottom: 0;
				}
				.slick-prev{
					top: auto;
					bottom: 25px;
					left: -61px;

					height: 60px;
					width: 60px;
					background: none;
					background-image: url(/img/arrow_white_left_10x20.png);
					background-repeat: no-repeat;
					background-position: center center;
					background-color: $blue-primary;

					z-index: 2;
				}
				.slick-next{
					top: auto;
					right: auto;
					bottom: 25px;
					left: 0;

					height: 60px;
					width: 60px;
					background: none;
					background-image: url(/img/arrow_white_right_10x20.png);
					background-repeat: no-repeat;
					background-position: center center;
					background-color: $blue-primary;

					z-index: 2;
				}

				.slick-prev::before, .slick-next::before{
					content: "";
				}

				@media(min-width: $l-screen + 1){
					&.positioned{
						position: relative;
						top: 50%;
						-webkit-transform:	translateY(-50%);
						-moz-transform:		translateY(-50%);
						-ms-transform:		translateY(-50%);
						-o-transform:		translateY(-50%);
						transform: 			translateY(-50%);
					}
				}

				@media(max-width: $l-screen){
					.slick-prev, .slick-next{
						display: none !important;
					}
				}
			}

			@media(min-width: $l-screen + 1){
				padding-left: 12.5%;
			}

			@media(max-width: $l-screen){
				display: block;
				width: 100%;
			}
		}
		

		.content{
			position: relative;
  
			.content-inner{
				padding: 0 25%;
				
				h6{
					color: $grey-medium;
					margin-bottom: 15px;
				}

				h2{ 
					margin-bottom: 20px;
				}


				.blue{
					margin-top: 28px;

					@media(min-width: $l-screen + 1){
						width: 100%;
						padding: 20px;
					}
				}

				@media(min-width: $l-screen + 1){
					&.positioned{
						position: relative;
						top: 50%;
						-webkit-transform:	translateY(-50%);
						-moz-transform:		translateY(-50%);
						-ms-transform:		translateY(-50%);
						-o-transform:		translateY(-50%);
						transform: 			translateY(-50%);
					}
				}

				@media(max-width: $l-screen){
					padding: 60px 20px;
					text-align: center;
				}
			}

			@media(max-width: $l-screen){
				display: block;
				width: 100%;
			}
		}

		&.even{
			@media(min-width: $l-screen + 1){
				background-color: $grey-background;

				.slider-wrp{
					left: 33.33333%;
				}
				.content-wrp{
					right: 66.66667%;
				}
	
				.slider{
					padding-left: 0;
					padding-right: 12.5%;

					.slider-inner{
						.slick-prev{
							left: auto;
							right: 0;
						}
						.slick-next{
							left: auto;
							right: -61px;
						}
					}
				}
				.content{
					-webkit-order: 1;
					order: 1;
				}
			}
		}
	}
}

.slider-boxes,
.grid-boxes {
	padding: 50px 0;
	background-color: $grey-background;

	@media(max-width: $m-screen){
		.container{
			padding-left: 0;
			padding-right: 0;
			width: 100%;
		}

		h3{
			margin-bottom: 35px;
		}
	}

	h3{
		margin-bottom: 50px;
		text-align: center;
	}

	.slider-boxes-slick{
		margin-top: 0;
		margin-bottom: 0;
		padding-left: calc(8.33333% - 10px);
		padding-right: calc(8.33333% - 10px);

		.slick-prev{
			left: 0;

			height: 20px;
			width: 10px;
			background: none;
			background-image: url(/img/arrow_blue_left_10x20.png);
			background-repeat: no-repeat;
			background-position: center center;

			z-index: 2;
		}
		.slick-next{
			right: 0;

			height: 20px;
			width: 10px;
			background: none;
			background-image: url(/img/arrow_blue_right_10x20.png);
			background-repeat: no-repeat;
			background-position: center center;

			z-index: 2;
		}
		.slick-prev::before, .slick-next::before{
			content: "";
		}

		@media(max-width: $m-screen){
			padding: 0;

			.slick-prev{
				left: 20px;
				background-image: url(/img/arrow_white_left_10x20.png);
			}
			.slick-next{
				right: 20px;
				background-image: url(/img/arrow_white_right_10x20.png);
			}
		}
	}

	.slider-box,
	.grid-box {
		padding-left: 10px;
		padding-right: 10px;

		.image{
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
		}
		h4{
			margin-bottom: 20px;
		}
		.content{
			background-color: #fff;
			padding: 30px;
		}

		@media(min-width: $m-screen + 1){
			.blue{
				width: 100%;
			}
		}

		@media(max-width: $m-screen){
			padding: 0;
			width: 100%;
			text-align: center;

			.content{
				background-color: transparent;
			}
		}
	}
}

.page-section{
	@media(min-width: $m-screen + 1){
		padding: 50px 0;

		.section-title-with-view-all{
			margin-bottom: 35px;
			position: relative;

			.title{
				padding-right: 100px;
			}
			.view-all{
				position: absolute;
				bottom: 0;
				right: 0;
				font-size: 14px;
				font-family: $futura-book;
			}
		}
	}

	@media(max-width: $m-screen){
		padding: 50px 0 0;

		.section-title-with-view-all{
			margin-bottom: 35px;

			.title{
				margin-bottom: 20px;
				text-align: center;
			}
			.view-all{
				text-align: center;
				font-size: 18px;
				font-family: $futura-book;
			}
		}

		.row:not(.no-mobile-margin) div[class*="col-"]{
			margin-bottom: 80px;
		}
	}
}

.latest{
	.latest-news{
		.image{
			margin-bottom: 35px;

			@media(max-width: $m-screen){
				margin-left: -15px;
				margin-right: -15px;
			}
		}
		.date{
			margin-bottom: 10px;
			color: $grey-medium;
			font-family: $futura-medium;
		}
		.blue{
			margin-top: 28px;

			@media(max-width: $l-screen){
				margin-bottom: 28px;
			}
		}
	}

	.latest-instagram{
		h5{
			color: $grey-medium;
			margin-bottom: 20px;
		}

		.instagram-images .row div[class*="col-"]{
			margin-bottom: 20px;
		}

		@media(min-width: $m-screen + 1){
			border: 1px solid $grey-line;
			padding: 25px;

			.blue{
				width: 100%;
			}
		}

		@media(max-width: $m-screen){
			padding: 5px;
		}
	}

	@media(max-width: $m-screen){
		text-align: center;
	}
}

.page-intro{
	padding: 50px 16.66667%;
	background-color: $grey-background;
	margin-bottom: 50px;

	.intro-text{
		font-family: $futura-book;
		font-size: 24px;
		color: $blue-primary;
	}
}

.boxes-grid{
	.box{
		margin-bottom: 30px;
		font-family: $futura-book;

		.box-image{
			background-position: center center;
			background-size: cover;
			width: 100%;
		}
		.box-content{
			border-left: 4px solid $grey-background;
			border-right: 4px solid $grey-background;
			padding: 30px;

			p{
				margin-bottom: 15px;
			}

			.title{
				font-size: 22px;
				color: $blue-primary;
				text-transform: uppercase;
			}
			.sub-title{
				font-size: 17px;
				color: $blue-primary;
				margin-top: -15px;
			}
			.date{
				font-size: 14px;
				color: $blue-tint-light;
			}
			.abstract{
				font-size: 17px;
				color: $grey-medium;
			}
		}

		.box-button .btn{
			width: 100%;
			padding: 15px;
		}
	}
}

.side-menu-box{
	padding: 15px;
	border: 4px solid $grey-background;
	font-family: $futura-medium;
	margin-bottom: 30px;

	.region{
		.region-title{
			padding: 15px;
			text-transform: uppercase;
			font-size: 16px;

			position: relative;
			cursor: pointer;

			background-color: $grey-background;
			color: $blue-primary;

	        margin-bottom: 10px;

			&:after{
				position: absolute;
				right: 15px;
				top: 50%;
				transform: translateY(-50%);

				font-size: 14px;

				font-family: "Font Awesome 5 Pro";
				content: "\f078";

				-webkit-transition: all 0.3s;
		        -moz-transition: all 0.3s;
		        -o-transition: all 0.3s;
		        transition: all 0.3s;
			}

			&.open{
				margin-bottom: 0;

				&:after{
					content: "\f077";
				}
			}
		}
		.region-content{
			padding: 15px;
			display: none;

			-webkit-transition: all 0.3s;
	        -moz-transition: all 0.3s;
	        -o-transition: all 0.3s;
	        transition: all 0.3s;

	        &.open{
				display: block;
			}

			.link{
				margin-top: 5px;
				margin-bottom: 5px;
				font-size: 17px;
				color: $grey-medium;

				a:hover{
					text-decoration: none;
				}

				&.active{
					a{
						color: $blue-primary;
					}
				}
			}
		}
	}
}

.pagination-bar{
	text-align: right;

	ul{
		li{
			width: 30px;
			height: 30px;
			line-height: 30px;
			text-align: center;

			padding: 0;
			background-color: $grey-background;

			color: $blue-primary;
			font-family: $futura-book;
			font-size: 14px;

			a{
				display: inline-block;
				width: 100%;
				height: 100%;
				color: $blue-primary;
				font-family: $futura-book;
				font-size: 14px;
			}

			&:hover, &.active{
				color: $white;
				background-color: $blue-primary;

				a:hover{
					text-decoration: none;
					color: $white;
				}
			}
		}
	}
}
