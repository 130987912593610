/* ------------------------------------- */
/* Global                                */
/* ------------------------------------- */
html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: $futura-book;
    font-size: 17px; /*15*/
    font-weight: 400;
    text-align: left;
    line-height: normal;
    color: $grey-medium;
    background-color: $white;
}

.container-fluid{
    max-width: 100%;
}

@media(min-width: $m-screen + 1){
    .container{
        width: 93%;
        max-width: 1340px;
    }
}

.row.padding-0{
    margin-right: 0;
    margin-left: 0;

    > div[class*="col-"]{
        padding-left: 0;
        padding-right: 0;
    }
}

.row.padding-10{
    margin-right: -10px;
    margin-left: -10px;

    > div[class*="col-"]{
        padding-left: 10px;
        padding-right: 10px;
    }
}

.row.padding-0-5{
    margin-right: -0.5px;
    margin-left: -0.5px;

    > div[class*="col-"]{
        padding-left: 0.5px;
        padding-right: 0.5px;
    }
}

.no-margin{
    margin: 0 !important;
}

/* ROW 5 COLUMNS */
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: $m-screen) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: $l-screen) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: $xl-screen) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}

/* ------------- Typography ------------ */
h1 {
    font-family: $trajan-bold;
    font-size:50px;
    font-weight:500;
    text-transform: uppercase;
    color:$blue-primary;
    margin: 0;
    padding: 0;
}
h2 {
    font-family: $trajan-bold;
    font-size:40px;
    font-weight:500;
    text-transform: uppercase;
    color:$blue-primary;
    margin: 0;
    padding: 0;
}
h3 {
    font-family: $trajan-bold;
    font-size:30px;
    font-weight:500;
    text-transform: uppercase;
    color:$blue-primary;
    margin: 0;
    padding: 0;

    /*@media(max-width: $m-screen){
        font-size: 40px;
    }*/
}
h4 {
    font-family: $futura-medium;
    font-size:22px; /*20*/
    font-weight:500;
    text-transform: uppercase;
    color:$blue-primary;
    margin: 0;
    padding: 0;
   /* @media(max-width: $m-screen){
        font-size: 38px;
    }*/
}
h5 {
    font-family: $futura-medium;
    font-size:16px; /*14*/
    font-weight:500;
    text-transform: uppercase;
    color:$blue-primary;
    margin: 0;
    padding: 0;
    /*@media(max-width: $m-screen){
        font-size: 28px;
    }*/
}
h6 {
    font-family: $futura-medium;
    font-size:14px; /*12*/
    font-weight:500;
    text-transform: uppercase;
    color:$blue-primary;
    margin: 0;
    padding: 0;
    /*@media(max-width: $m-screen){
        font-size: 28px;
    }*/
}

p {
    margin-bottom: 28px;

    /*@media(max-width: $m-screen){
        font-size: 34px;
    }*/

    &:last-of-type {
        margin-bottom: 0;
    }

    &.small-text {
        font-size:14px; /*12*/
    }
        
    &.large-text {
        font-size:24px;
        color: $blue-primary;

        /*@media(max-width: $m-screen){
            font-size: 40px;
        }*/
    }
}

a:link, a:visited, a:active {
    font-weight:normal; 
    text-decoration:none;
    color:$grey-medium;
}
a:hover {
    text-decoration:underline; 
    color:$blue-primary;
}
a img:hover {
    text-decoration:none; 
    opacity:0.9;
}

.text-blue{
    color: $blue-primary;
}

/* Buttons */
button.blue, input[type="submit"].blue, a.blue {
    font-family:$futura-book;
    font-size:16px; /*14*/
    font-weight:500;
    text-transform: uppercase;
    background-color:$blue-primary;
    border:none;
    padding:20px 60px;
    color:$white;
    text-align:center;
    line-height:normal; 
    outline:none;
    cursor:pointer;
    position:relative;
    transition:background-color 0.2s;

    /*@media(max-width: $m-screen){
        font-size: 28px;
    }*/
}

button.blue:hover, input[type="submit"].blue:hover, a.blue:hover  {
    background-color:#171441;
    color: $white;
}
    
button.blue:active, input[type="submit"].blue:active, a.blue:active  {
    top:1px;
    color: $white;
}

a.blue:focus{
    color: $white;
}

/* Cookie */
.cookie-wrp{
    padding: 75px 20px 20px 20px !important;
    background-color: transparent;
    background-image: url("/img/cookie_triangle.png");
    background-position:0 100%;
    background-size:100% auto;
    border: 0;
    position: fixed;
    bottom: 0;
    right:0;
    z-index: 100000;
    width: 180px;
    height:180px;
    margin-right: 0;
    text-align:right;
    p{
        line-height:normal;
        margin:0 0 4px 0;
        font-size: 15px;
    }
    .fa,
    #cookie_close_btn{
        color:$blue-primary;
        font-size:20px;
    }
    #cookie_close_btn{
        text-decoration:none;
        border-bottom: 1px solid $blue-primary;
    }
    #cookie_close_btn:hover{
        text-decoration:none;
        border-bottom: 0;
    }
}
.cookie-wrp .close {
    width: 40px;
    height: 40px;
    top: -71px;
    right: -31px;
    background-color: #AC8C29;
    color: #fff;
    position: relative;
    z-index: 1;
    opacity: 1;
}

/* FORM */

form{
    position: relative;

    .form-group{
        margin-bottom: 25px;
    }

    p{
        font-family: $futura-medium;
        font-size: 16px;
    }

    label{
        font-weight: normal;
        text-transform: uppercase;
        color: $blue-primary;
        font-size: 16px;
        font-family: $futura-medium;

        .label-help-text{
            font-family: $futura-medium;
            color: $grey-medium;
            font-size: 16px;
            text-transform: none;
        }
    }

    input[type="text"],
    input[type="email"],
    select{
        height: 48px !important;
        background-color: $grey-background;
        border: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        font-size: 16px !important;
        color: $grey-medium !important;

        width: 100% !important;

        &::-webkit-input-placeholder {
            font-family: $futura-book-oblique;
            font-size: 14px;
        }

        &:-ms-input-placeholder {
            font-family: $futura-book-oblique;
            font-size: 14px;
        }

        &:-moz-placeholder {
            font-family: $futura-book-oblique;
            font-size: 14px;
        }

        &::-moz-placeholder {
            font-family: $futura-book-oblique;
            font-size: 14px;
        }
    }

    button.btn-number{
        float: left;
    }
    input.number-input{
        width: 60px !important;
        height: 49px !important;
        float: left;

        color: $blue-primary !important;
        font-size: 17px !important;
        text-align: center;
    }

    .submit-inner-button{
        width: 16px;
        height: 16px;

        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);

        font-size: 16px;
        color: $blue-primary;
        cursor: pointer;
    }

    &.form-with-background{
        input[type="text"],
        input[type="email"],
        select{
            background-color: $white;
        }
    }

    &.form-all-white{
        input[type="text"],
        input[type="email"],
        select{
            background-color: $white;
            border: 1px solid $grey-line !important;
        }
    }
}

/* --------------- Media --------------- */
@media (max-width: $l-screen) {

}
@media (max-width: $xs-screen) {

}

