.reservations{
	p{
		font-family: $futura-medium;
	}

	.blue{
        padding: 14.5px 50px;

        &.small{
        	padding-left: 30px;
        	padding-right: 30px;
        }
    }

	#booking_header{
		background-color: $grey-background;

		h3{
			padding-top: 60px;
			padding-bottom: 25px;
		}
		p{
			padding-bottom: 55px;
		}
	}

	.booking-bar{
		.booking-bar-state{
			background-color: #d2d7de;
			padding: 20px 10px;
			text-transform: uppercase;
			text-align: center;

			font-family: $futura-book;
			font-size: 14px;
			color: $blue-primary;

			&.selected{
				background-color: $blue-primary;
				color: $white;
			}
		}
	}

	.button-collapse{
		background-color: $grey-background;
		color: $blue-primary;
		text-transform: uppercase;
		padding: 15px 45px 15px 30px;

		font-family: $futura-medium;
		font-size: 14px;

		position: relative;
		cursor: pointer;

		-webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;

		&:after{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 30px;

			font-family: "Font Awesome 5 Pro";
			content: "\f078";

			-webkit-transition: all 0.5s;
	        -moz-transition: all 0.5s;
	        -o-transition: all 0.5s;
	        transition: all 0.5s;
		}

		&.clicked-once, &:hover{
			background-color: $blue-primary;
			color: $white;
			margin-bottom: 0;
		}

		&.clicked-once:after, &.clicked:after{
			content: "\f077";
		}
	}

	#com_reservations{
		margin-top: 60px;
		margin-bottom: 30px;

		.checkout-intro-text{
			color: $blue-primary;
			font-size: 18px;
			margin-bottom: 30px;
		}

		.please-select {
			color: #fff;
			display:none;
			width:100%;
			float:left;
			background-color: #c42e25;
			margin-bottom: 30px;
			padding: 15px;
			text-align: center;
		}

		.occupancy{
			margin-top: 10px;
			margin-bottom: 30px;

			.title{
				color: $blue-primary;
			}
		}

		.results{
			list-style: none;
			margin: 0;
			padding: 0;

			border-top: 1px solid $grey-line;

			> li{
				margin: 0;
				border-bottom: 1px solid $grey-line;
				position: relative;
				padding: 25px 0;

				&:after{
					content: "";
					display: table;
					clear: both;
				}
			}

			/* IMPORTED FROM HPH */
			/*.top.title {
				padding: 0;
				cursor: pointer;
			}*/

			.top.tooltip {
				padding: inherit;
				margin-top: inherit;
				cursor: inherit;
				width: auto;
				float: none;
			}
			.top.tooltip .tooltip-arrow {
				bottom: -3px;
			}

			.top-display {
				max-width: 34%;
				float: left;
				padding: 0 30px 0 0;
				box-sizing: border-box;
				position: relative;
			}

			.available-rooms .tab-padding-reset {
				padding: 0 !important;
			}

			.top-display img {
				width: 100%;
			}
			.top-display .gallery {
				margin: 0px;
			}
			.top-display .gallery .image-link {
			    width: 105.6px;
			    height: 105.6px;
			    float: left;
			    overflow: hidden;
			    position: relative;
			    background-position: center center;
				background-size: cover;
			}
			.top-display .gallery.gallery-reset .image-link {
			    width: 100%;
			    height: auto;
			    float: left;
			    background-image: none !important;
			}
			.top-display .gallery .image-link::after {
			    content: "\f00e";
			    font-family: "Font Awesome 5 Pro";
			    width: 30px;
			    height: 30px;
			    line-height: 30px;
			    background-color: $blue-primary;
			    position: absolute;
			    bottom: 0;
			    right: 0px;
			    font-size: 12px;
			    text-align: center;
			    z-index: 1;
			    color: #fff;
			}
			.top-display .gallery .gallery-num {
				width: 30px;
			    height: 30px;
			    line-height: 30px;
			    background-color: $blue-tint-light;
			    position: absolute;
			    bottom: 0;
			    right: 30px;
			    font-size: 13px;
			    text-align: center;
			    z-index: 1;
			    color: #fff;
			}

			.top-details {
				float: left;
				width: 66%;
				box-sizing: border-box;

			}
			.top-details.no-gallery {
				width: 100%;

			}
			.top:first-child {
				margin: 0;
			}
			.top .price,  .top h3 {
				/*float: left; */

				margin: 0;
				padding: 0;
				color: #333;
			}
			.top .price {
				width: 100%; /*220px;*/
				color: $blue-primary;
				font-size: 17px;
			}
			.top .price span {
				font-size: 17px;
				color: $blue-primary;
			}
			.content {
				margin: 0;
			}
			.content .uk-tab {
				padding: 0;
				margin-top: 20px;
			}
			.content .uk-tab a {
				padding: 10px;
				margin: 0 10px 0 0;
			}
			.content .uk-tab > li:first-child a {
				margin-left: 0;
			}
			.content .includes,  .content .conditions {
				margin: 0;
				padding: 0;
				font-size: 16px;
			}
			.content .includes span,  .content .conditions span {
				display: block;
				font-weight: bold;
			}
			.content .available-rooms {
				background: #FFF;
				margin: 0 0 10px 0;
			}
			.content .available-room-list {
				list-style: none;
				margin: 0;
				padding: 0;
				width: 100% !important;
			}
			.content .available-room-list li {
				margin: 0;
				padding: 0;
				border: 0; /* stroke */
				margin: 0 0 20px 0;
				background-color: $white;
				position: relative;

				&:last-child{
					margin-bottom: 0;
				}
			}
			.content .available-room-list label {
				float: left;
				margin: 0;
				padding: 0;
				width: 100%;

				font-weight: normal;
			}
			.content form input[type="submit"] {
				margin-top: 15px;
			}
			.content form input[type="submit"]:hover {
				margin-top: 15px;
			}

			.radio-box {
				position: absolute;
				left: 0;
				top: 0;
				margin: 0px 20px 0 0;
				float: left;
				height: 100%;
				width: 73px;
				cursor: pointer;
				box-sizing: border-box;
				background-color: #d2d7de; /* layer fill content */
			}
			li.selected .radio-box {
				background-color: $blue-primary; /* layer fill content */
			}
			.radio-box input {
				margin: 0 30px;
				width: auto;
				transform: translateY(-50%);
				position: absolute;
				top: 50%;
				cursor: pointer;
			}

			.radio-box input:focus {
				outline: none;
			}

			.room-details {
				padding: 20px 20px 20px 100px;
				width: 100%;
				box-sizing: border-box;
			}
			.room-details .title,  .room-details .price {
				font-size: 16px;
				color: #000;
				margin: 0 0 15px 0;
				padding: 0;

				font-family: $futura-medium;
				color: $blue-primary;
			}
			.room-details .price {
				float: right;
			}
			.room-details .desc {
				font-size: 17px;
				font-family: $futura-book;
				color: $grey-medium;

				margin: 0 0 10px 0;
			}
			.room-details .tc a,  .tc a {
				font-size: 17px;
				font-family: $futura-book;
				color: $grey-medium;
			}
			/* END IMPORTED FROM HPH */

			.content .available-rooms .section{
				padding: 30px;
				background-color: $grey-background;
			}

			.top-details{

				p.title{
					font-family: $futura-medium;
					font-size: 20px;

					color: $blue-primary;
					text-transform: uppercase;
					margin-bottom: 5px;
				}

				.price{
					font-family: $futura-medium;
					font-size: 17px;
					color: $blue-primary;
				}

				p.description{
					margin-top: 25px;
					color: $grey-medium;
				}
			}

			.rates-block{
				margin-top: 20px;
		
				.button-rate{
					margin-top: 10px;
				}
			}
		}

		.b_pk_dt_ct{
			margin-top: 30px;
			.blue{
				padding: 15px;
			}
		}

		.additional-info-textarea{
			border: 4px solid $grey-background;
			width: 100%;
		}

		/* Payment */
		.box-panel-title{
			background-color: #d2d7de;
			color: $blue-primary;
			text-align: center;
			padding: 15px;
			text-transform: uppercase;

			font-family: $futura-medium;
			font-size: 20px;
		}
		.box-panel{
			background-color: $grey-background;
			padding: 30px;
			margin-bottom: 30px;
		}
		.payment-totals{
			border-top: 1px solid $grey-line;
			border-bottom: 1px solid $grey-line;
			font-family: $futura-medium;
			font-size: 16px;
			color: $blue-primary;
			text-transform: uppercase;
			margin-bottom: 30px;

			.help-text{
				text-transform: none;
				color: $grey-medium;
			}

			.row{
				margin-top: 8px;
				margin-bottom: 8px;
			}
		}
		.payment-summary{
			padding: 30px;
			padding-bottom: 1px;
			border: 4px solid $grey-background;
			border-bottom: 0;

			.summary-title{
				font-size: 20px;
				color: $blue-primary;
				text-transform: uppercase;
			}
		}
		.payment-button-collapse{
			text-align: center;

			.button-collapse{
				margin-bottom: 30px;
			}

			&:hover, &:focus, &:active{
				text-decoration: none;
			}
		}
	}

	#mod_reservation_summary {
		margin-bottom: 30px;

		/* IMPORTED FROM HPH */
		p, form {
		    margin: 0;
		    padding: 0;
		    font-size: 1em;
		}

		.clr {
		    content: ".";
		    display: block;
		    height: 0;
		    clear: both;
		    visibility: hidden;
		}

		&.top {
			position: absolute;
			margin: 96px 0 0 0;
			top: 0;
		}
		&.bottom {
			position: absolute;
			bottom: 0;
			top: auto;
		}
		&.moving {
			position: fixed;
			top: 0;
			bottom: auto;
			margin: 0;
		}
		&.to-small {
			height: 100%;
			overflow: hidden;;
		}
		&.to-small:hover {
			overflow-y: scroll;
		}

		.summary-room h3 {
			font-family:"Lato", sans-serif;
		    text-transform: uppercase;
		    height: auto;
		    margin: 5px 0 0 0;
		    padding: 9px 11px;
			background-color:#ac8c29;
			font-size:14px;
			line-height:1;
			color:#fff;
		}
		.summary-room h3 span{
			display:none;
			float: right;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			width: 14px;
			height: 14px;
			transition: transform 0.4s linear 0s;
		}

		.summary-room .details{
			padding:20px 10px;
			display:block;
		}

		.summary-room  h4 {
		    font-family: "Lato", sans-serif;
		    padding: 0;
		    font-weight: normal;
		    font-size: 12px;
			color:#ac8c29;
			margin:0 0 10px 0;
		}

		.summary-room .details ul {
		    font-size: 14px;
		    margin: 0 0 20px 0;
		}
		.summary-room .details > ul:last-child{
			margin: 0 0 0 0;
		}

		.summary-total-panel {
			margin: 20px 0 0 0;
			padding:0;
		}
		.summary-total-panel p{
			margin:0 0 10px 0;
			font-size:18px;
			color:#000;
		}
		/* END IMPORTED FROM HPH */

		.title{
			margin: 60px 0 0 0;
			background-color: $blue-primary;
			color: $white;
			padding: 20px 30px;
			font-family: $futura-medium;
			font-size: 20px;
			text-transform: uppercase;
		}
		.box{
			position:relative;
			border: 4px solid $grey-background; /* stroke */
			border-top: 0;
			background-color: $white; /* layer fill content */
		    padding: 30px;
		}


	}
	.summary-wrp {
		.details-title{
			color: $blue-primary;
			font-family: $futura-medium;
			font-size: 16px;
			background-color: $grey-background;
			padding: 5px 15px;
			text-transform: uppercase;
		}
		.details-box{
			padding: 10px 15px;
			font-family: $futura-book;
			font-size: 17px;
			line-height: 17px;

			.row{
				margin-top: 8px;
				margin-bottom: 8px;
			}
		}
		.detail-name{
			color: $blue-tint-light;
		}
		.detail-value{
			color: $grey-medium;
		}
		.special-box{
			border: 4px solid $grey-background;
			padding: 3px 11px;
			font-family: $futura-book;
			font-size: 17px;
			line-height: 17px;
			margin-bottom: 18px;

			&.payment-special-box{
				margin-top: 10px;
			}

			.special-title{
				color: $blue-primary;
				font-family: $futura-medium;
				font-size: 16px;
				text-transform: uppercase;
				margin-top: 8px;
				margin-bottom: 8px;
			}
			.special-item{
				border-top: 1px solid $grey-background;
				padding-top: 5px;
				padding-bottom: 5px;
			}
			.row{
				margin-top: 8px;
				margin-bottom: 8px;
			}
		}
		.special-name{
			color: $blue-primary;
		}
		.total{
			color: $blue-primary;
			font-family: $futura-medium;
			font-size: 16px;
			padding: 15px 0;
			border-top: 1px solid $grey-line;
			border-bottom: 1px solid $grey-line;
			text-transform: uppercase;

			margin-bottom: 20px;
		}
	}
	#reservation_summary_overlay{
	    position:absolute;
	    top:0;
	    right:0;
	    bottom:0;
	    left:0;
	    background-color:#fff;
	}
	#reservation_summary_overlay:before{
	    content:"Calculating...";
	    position:absolute;
	    text-align:center;
	    top:50%;
	    right:0;
	    bottom:0;
	    left:0;
	}
	/* END Reservations Summary */

	.button{
		padding: 15px;
		font-weight: normal;
		text-transform: uppercase;
		cursor: pointer;

		&.button-primary{
			color: $white;
			background-color: $blue-primary;
		}
		&.full-width{
			width: 100%;
			text-align: center;
		}
	}
}

.pv-progress-bar{
    width:100%;
    float:left;
    margin:0 0 30px 0;
    padding: 0;
    list-style: none;

    li{
        position:relative;
        float:left;
        margin:0;
        margin-right: 10px;
        max-width:20%;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;

        background-color: $grey-background;

        &.max-2{
            max-width:50%;
        }
        &.max-3{
            max-width:33%;
        }
        &.max-4{
            max-width:25%;
        }

        a{
            padding: 60px 15px 15px;
            text-align:center;
            display:block;
            margin: 0;
            max-width: 100%;
            position: relative;
            z-index: 9;
            color:$grey-medium;
            -webkit-transition: all 0.3s; /* Safari */
            -moz-transition: all 0.3s; /* mozila */
            -o-transition: all 0.3s; /* opera */
            transition: all 0.3s;
            outline: 0;

            &.shake {
                animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                perspective: 1000px;
            }
        }

        a:hover,a:active,a:focus{
            text-decoration:none;
        }

        &:before{
            content:"\f236";
            position:absolute;
            left:50%;
            transform: translateX(-50%);
            margin:0;
            display:inline-block;
            top:15px;
            font-family: "Font Awesome 5 Pro";
            font-size:30px;
            color:$grey-line;
            text-align: center;
            max-width:35px;
        }

        &.active{
            background-color: $blue-primary;
        }
        &.active a{
            color: $white;
        }
        &.active:before{
            content:"\f236";
            color:$grey-medium;
        }
        &.active.done:before,
        &.done:before{
            content:"\f236";
            color:$blue-tint-light;
        }

        &:after{
        	position:absolute;
			content:"";
			width:0px;
			height:0px;
			border-top: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid transparent;
			border-left: 10px solid transparent;
			bottom: -20px;
			left:50%;
            transform: translateX(-50%);
            -webkit-transition: all 0.3s; /* Safari */
            -moz-transition: all 0.3s; /* mozila */
            -o-transition: all 0.3s; /* opera */
            transition: all 0.3s;
        }
        &.active:after{
        	border-top-color: $blue-primary;
        }
        
    }
}