/* ------------------------------------- */
/* Front end CMS classes                 */
/* ------------------------------------- */

.edit-page {
	position: fixed;
	bottom: 50px;
	right:50px;
}

.cms-button {
    font-family:$futura-book;
    font-size:10px;
    font-weight:200;
    text-transform: uppercase;
    background-color:$blue-primary;
    border:none;
    padding:10px 20px;
    color:$white;
    text-align:center;
    line-height:normal; 
    outline:none;
    cursor:pointer;
    position:relative;
    transition:background-color 0.2s;

    /*@media(max-width: $m-screen){
        font-size: 28px;
    }*/

}

cms-button:hover  {
    background-color:#171441;
    color: $white;
}