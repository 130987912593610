.footer-main{
	background-color: $grey-background;
	padding: 50px 0;

	.footer-heading{
		border-bottom: 1px solid $grey-line;
		margin-bottom: 10px;

		h5{
			margin-bottom: 5px;
		}
	}

	ul{
		padding: 0;

		li{
			list-style: none inside;
		}
	}

	@media(max-width: $l-screen){
		.footer-block{
			margin-bottom: 20px;
		}
	}

	@media(max-width: $m-screen){
		text-align: center;

		img{
			display: block;
			margin: 0 auto;
		}
	}
}

.footer-bottom{
	background-color: $blue-primary;
	padding: 20px 0;
	color: $white;

	.list-inline{
		margin-bottom: 0;
	}

	a:link, a:visited, a:active {
	    color:$white;
	}
	a:hover {
	    text-decoration:underline; 
	}

	.footer-copy{
		text-align: right;

		@media(max-width: $m-screen){
			text-align: center;
		}
	}
	.footer-links{
		@media(max-width: $m-screen){
			text-align: center;
			margin-bottom: 20px;
		}
	}
}